import React from 'react';
import { elementOnSpan } from '../TechnicalCutoutsView.const';
import { TechnicalCutoutsViewElementCutouts } from '../TechnicalCutoutsViewElementCutouts/TechnicalCutoutsViewElementCutouts';

export const TechnicalCutoutsViewElementOnSpan = ({ element, scale, cssScale }) => {
  switch (element) {
    case elementOnSpan.TECHNICAL_CUTS:
      return <TechnicalCutoutsViewElementCutouts scale={scale} cssScale={cssScale} />;
    case elementOnSpan.INVISIBLE_MAILBOX:
    case elementOnSpan.MAILBOX:
      return <div>Dostępne wkrótce.</div>;
    default:
      return null;
  }
};

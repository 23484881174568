export const getCurrentScale = (spanWidth, spanHeight) => {
  switch (true) {
    case spanHeight > 625 || spanWidth > 625:
      return 1;
    case spanHeight > 420 || spanWidth > 420:
      return 2;
    case spanHeight > 310 || spanWidth > 310:
      return 3;
    case spanHeight > 250 || spanWidth > 250:
      return 4;
    case spanHeight > 200 || spanWidth > 200:
      return 5;
    case spanHeight > 180 || spanWidth > 180:
      return 6;
    case spanHeight > 150 || spanWidth > 150:
      return 7;
    case spanHeight > 135 || spanWidth > 135:
      return 8;
    case spanHeight > 115 || spanWidth > 115:
      return 9;
    case spanHeight > 100 || spanWidth > 100:
      return 10;
    case spanHeight > 85 || spanWidth > 85:
      return 12;
    case spanHeight > 75 || spanWidth > 75:
      return 14;
    case spanHeight > 65 || spanWidth > 65:
      return 16;
    case spanHeight > 50 || spanWidth > 50:
      return 18;
    default:
      return 20;
  }
};

export const getCssCurrentScale = (spanWidth, spanHeight) => {
  switch (true) {
    case spanWidth > 3100:
      return 0.37;
    case spanWidth > 2550:
      return 0.4;
    case spanHeight > 2100 || spanWidth > 2100:
      return 0.5;
    case spanHeight > 1800 || spanWidth > 1800:
      return 0.6;
    case spanHeight > 1600 || spanWidth > 1600:
      return 0.7;
    case spanHeight > 1250 || spanWidth > 1250:
      return 0.8;
    default:
      return 1;
  }
};

export const spanSizes = {
  minWidth: 10,
  maxWidth: 3500,
  minHeight: 10,
  maxHeight: 2500,
};

import React from 'react';
import styled from 'styled-components';

export const Footer = () => (
  <Wrapper>
    <Column>
      <Copyrights>&copy; Konsport</Copyrights>
    </Column>
    <Column>
      <Address>
        <AddressItem>Jana Kilińskiego 75</AddressItem>
        <AddressItem>95-083 Kazimierz</AddressItem>
        <AddressItem>+48 43 677 50 63</AddressItem>
        <AddressItem>biuro@konsport.com.pl</AddressItem>
      </Address>
    </Column>
  </Wrapper>
);

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 3.6rem 0;
  color: ${({ theme: { colors } }) => colors.neutral20};
  font-size: 1.5rem;
  font-weight: 400;
`;

const Column = styled.div``;

const Copyrights = styled.span``;

const Address = styled.address`
  display: flex;
  font-style: normal;
`;

const AddressItem = styled.span`
  display: block;

  &:not(:last-of-type) {
    padding: 0 2rem;
  }
`;

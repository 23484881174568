import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Header } from 'components/Header/Header';
import { Tabs } from 'components/Tabs/Tabs';
import { Footer } from 'components/Footer/Footer';
import { Information } from 'components/Information/Information';
import { SendForm } from 'components/SendForm/SendForm';

export const PageTemplate = ({ children }) => (
  <Wrapper>
    <Header />
    <Tabs>{children}</Tabs>
    <SendFormWrapper id="send_project">
      <Information />
      <SendForm />
    </SendFormWrapper>
    <Footer />
  </Wrapper>
);

PageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SendFormWrapper = styled.div`
  display: flex;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Message = ({ children, lines }) =>
  children ? (
    <Text>{children}</Text>
  ) : (
    <Text>
      {lines.map(line => (
        <Value key={Math.random()}>{line}</Value>
      ))}
    </Text>
  );

Message.propTypes = {
  children: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.string),
};

Message.defaultProps = {
  children: '',
  lines: [],
};

const Text = styled.h2`
  display: block;
  padding: 2rem 0;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
`;

const Value = styled.span`
  display: block;
`;

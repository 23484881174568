import React from 'react';
import styled from 'styled-components';

export const Label = ({ children, asWhite, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Wrapper asWhite={asWhite} {...rest}>
    {children}
  </Wrapper>
);

const Wrapper = styled.label`
  display: block;
  padding: 0;
  margin: 0 0 1rem;
  color: ${({ asWhite, theme: { colors } }) => (asWhite ? colors.white : colors.neutral50)};
  font-size: 1.5rem;
  font-weight: 400;
`;

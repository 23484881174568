import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const buttonStyleTypes = {
  SECONDARY: 'SECONDARY',
  PRIMARY: 'PRIMARY',
  NEUTRAL: 'NEUTRAL',
  GREY: 'GREY',
};

export const Button = ({
  className,
  onClick,
  children,
  disabled = false,
  type,
  icon,
  iconComponent: Component,
  styleType,
}) => (
  <ButtonWrapper>
    <ButtonClick
      type={type}
      onClick={onClick}
      icon={icon}
      styleType={styleType}
      disabled={disabled}
      className={className}
    >
      {icon && <Icon src={icon} alt="" />}
      {Component && <Component />}
      <span>{children}</span>
    </ButtonClick>
  </ButtonWrapper>
);

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

const ButtonWrapper = styled.div``;

const Icon = styled.img`
  display: block;
  margin: 0 2rem;
`;

const getBackground = (styleType, colors) => {
  switch (styleType) {
    case buttonStyleTypes.PRIMARY:
      return colors.primary;
    case buttonStyleTypes.SECONDARY:
      return colors.secondary;
    case buttonStyleTypes.NEUTRAL:
      return colors.neutral50;
    case buttonStyleTypes.GREY:
      return colors.neutral10;
    default:
      return colors.neutral50;
  }
};

const ButtonClick = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 4rem;
  line-height: 4rem;
  font-size: 1.5rem;
  padding: ${({ icon }) => (icon ? '0 3rem 0 0' : '0 3rem')};
  margin: 0;
  background: ${({ styleType, theme: { colors } }) => getBackground(styleType, colors)};
  border-radius: 2rem;
  color: ${({ theme: { colors } }) => colors.white};
  border: none;

  &:disabled {
    opacity: 0.2;
    cursor: auto;
  }
`;

import { POSITION } from './consts';

export const createConditions = (x, y) => {
  const res = {
    leftTop: false,
    top: false,
    rightTop: false,
    left: false,
    right: false,
    leftBottom: false,
    bottom: false,
    rightBottom: false,
  };
  res.leftTop =
    (x === POSITION.RIGHT && y === POSITION.BOTTOM) ||
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.CENTER) ||
    (x === POSITION.CENTER && y === POSITION.BOTTOM);
  res.top =
    (x === POSITION.RIGHT && y === POSITION.BOTTOM) ||
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.CENTER) ||
    (x === POSITION.LEFT && y === POSITION.CENTER) ||
    (x === POSITION.LEFT && y === POSITION.BOTTOM) ||
    (x === POSITION.CENTER && y === POSITION.BOTTOM);
  res.rightTop =
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.LEFT && y === POSITION.CENTER) ||
    (x === POSITION.LEFT && y === POSITION.BOTTOM) ||
    (x === POSITION.CENTER && y === POSITION.BOTTOM);
  res.left =
    (x === POSITION.RIGHT && y === POSITION.BOTTOM) ||
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.TOP) ||
    (x === POSITION.CENTER && y === POSITION.TOP) ||
    (x === POSITION.CENTER && y === POSITION.BOTTOM);
  res.right =
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.CENTER && y === POSITION.TOP) ||
    (x === POSITION.LEFT && y === POSITION.TOP) ||
    (x === POSITION.LEFT && y === POSITION.CENTER) ||
    (x === POSITION.LEFT && y === POSITION.BOTTOM) ||
    (x === POSITION.CENTER && y === POSITION.BOTTOM);
  res.leftBottom =
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.TOP) ||
    (x === POSITION.CENTER && y === POSITION.TOP);
  res.bottom =
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.CENTER) ||
    (x === POSITION.RIGHT && y === POSITION.TOP) ||
    (x === POSITION.CENTER && y === POSITION.TOP) ||
    (x === POSITION.LEFT && y === POSITION.TOP) ||
    (x === POSITION.LEFT && y === POSITION.CENTER);
  res.rightBottom =
    (x === POSITION.CENTER && y === POSITION.CENTER) ||
    (x === POSITION.CENTER && y === POSITION.TOP) ||
    (x === POSITION.LEFT && y === POSITION.TOP) ||
    (x === POSITION.LEFT && y === POSITION.CENTER);

  return res;
};

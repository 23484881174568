import * as yup from 'yup';

export const elementOnSpan = {
  TECHNICAL_CUTS: 'TECHNICAL_CUTS',
  INVISIBLE_MAILBOX: 'INVISIBLE_MAILBOX',
  MAILBOX: 'MAILBOX',
};

export const formikKeys = {
  spanWidth: 'spanWidth',
  spanHeight: 'spanHeight',
  elementOnSpan: 'elementOnSpan',
  elementOnSpanWidth: 'elementOnSpanWidth',
  elementOnSpanHeight: 'elementOnSpanHeight',
};

export const initialValues = {
  [formikKeys.spanWidth]: 0,
  [formikKeys.spanHeight]: 0,
  [formikKeys.elementOnSpan]: null,
  [formikKeys.elementOnSpanWidth]: 0,
  [formikKeys.elementOnSpanHeight]: 0,
  projectName: '',
  actionType: '',
  comment: '',
  email: '',
};

export const validationSchema = yup.object().shape({
  projectName: yup.string().max(255).required('Proszę podać nazwę projektu.'),
  email: yup.string().email('Adres email jest niepoprawny.').required('Proszę podać email.'),
  comment: yup.string().max(255),
});

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from 'Router/routes';
import logo from 'assets/logo.svg';

export const Logo = () => (
  <StyledLink to={routes.technicalCutouts}>
    <Vector src={logo} alt="Konsport" title="Konsport" />
  </StyledLink>
);

const StyledLink = styled(Link)`
  display: block;
  width: 14%;
  max-width: 19rem;
  min-width: 13rem;
`;

const Vector = styled.img`
  display: block;
  width: 100%;
`;

import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { HolderPolesCreatorBoard } from './HolderPolesViewCreatorBoard';
import { HolderPolesCreatorEmptyState } from './HolderPolesViewCreatorEmptyState';

export const HolderPolesViewCreator = () => {
  const formik = useFormikContext();

  return (
    <Wrapper id="board">
      <BoardWrapper>
        {formik.values.holderSizes && (
          <Place>
            <PlaceName>Posesja</PlaceName>
          </Place>
        )}
        {formik.values.holderSizes ? <HolderPolesCreatorBoard /> : <HolderPolesCreatorEmptyState />}
        {formik.values.holderSizes && (
          <Place street>
            <PlaceName>Ulica</PlaceName>
          </Place>
        )}
      </BoardWrapper>
    </Wrapper>
  );
};

const Place = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ street }) => (street ? '#ECECEC' : '#ECF6EF')};
  color: ${({ street, theme }) => (street ? theme.colors.black : theme.colors.secondary)};
`;

const PlaceName = styled.span`
  font-size: 2rem;
`;

const BoardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 2rem;
  overflow: hidden;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
`;

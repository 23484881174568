import styled from 'styled-components';
import background from 'assets/background-light.svg';

export const SpanElement = styled.div`
  position: relative;
  height: ${({ height }) => `${height + 2}px`};
  width: 16px;
  margin: ${({ left }) => (left ? '0 8px 0 0' : '0 0 0 8px')};
  background: ${({ theme }) => theme.colors.neutral20};

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: ${({ left }) => (left ? '100%' : 'auto')};
    right: ${({ left }) => (left ? 'auto' : '100%')};
    width: 8px;
    height: 4px;
    background: ${({ theme }) => theme.colors.neutral20};
  }

  &::before {
    top: 5%;
  }

  &::after {
    bottom: 5%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: content-box;
  width: ${({ width, scale }) => `${width * scale}px`};
  height: ${({ height, scale }) => `${height * scale}px`};
  border: 1px solid #c0c0c0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.white};
    background-image: url(${background});
  }
`;

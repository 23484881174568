import { useFormikContext } from 'formik';
import React from 'react';

import { HolderPolesViewBoard } from 'Router/HolderPolesView/HolderPolesViewBoard';
import { Message } from 'components/Message/Message';
import { HolderPolesHoleSpacing } from './HolderPolesHoleSpacing';
import { HolderPolesViewCreatorBoardPosition } from './HolderPolesViewCreatorBoardPosition';

import { HolderPolesViewPole } from './HolderPolesViewPole';
import { useHolderPolesContext } from './useHolderPolesContext';

export const HolderPolesCreatorBoard = () => {
  const formik = useFormikContext();
  const { handlePoleTrash, handlePoleRotation } = useHolderPolesContext();

  const holderWidth = formik.values.holderRotation
    ? formik.values.holderSizes?.height
    : formik.values.holderSizes?.width;

  const holderHeight = formik.values.holderRotation
    ? formik.values.holderSizes?.width
    : formik.values.holderSizes?.height;

  const poleWidth = formik.values.holderRotation
    ? formik.values.poleSizes?.height
    : formik.values.poleSizes?.width;

  const poleHeight = formik.values.holderRotation
    ? formik.values.poleSizes?.width
    : formik.values.poleSizes?.height;

  return (
    <HolderPolesViewBoard width={holderWidth} height={holderHeight}>
      {formik.values.poleSizes !== null ? (
        <>
          <HolderPolesViewPole
            width={poleWidth}
            height={poleHeight}
            handleTrash={handlePoleTrash}
            rotation={formik.values.polesRotation}
            handleRotate={() => handlePoleRotation(formik)}
            positionX={formik.values.polePosition?.x}
            positionY={formik.values.polePosition?.y}
          />
          <HolderPolesViewCreatorBoardPosition />
          <HolderPolesHoleSpacing holderRotation={formik.values.holderRotation} />
        </>
      ) : (
        <>
          <Message lines={['Wybierz rodzaj słupka', 'aby dodać go na marce']} />
          <Message lines={['Pamiętaj, że możesz zmienić', 'położenie słupka na marce']} />
        </>
      )}
    </HolderPolesViewBoard>
  );
};

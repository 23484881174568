import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 35px 0 0;
  flex: 1;
  transform: ${({ cssScale }) => `scale(${cssScale})`};
  transform-origin: top;
`;

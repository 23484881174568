import styled, { css } from 'styled-components';
import { Input } from 'components/Input/Input';

const minimalSizeForKeepingCutoutsSettingsOnElement = 180;

export const Move = styled.div`
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral50};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  z-index: 9999;
`;

export const Settings = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: calc(100% + 5px);
  padding: 1.2rem;
  background: ${({ theme }) => theme.colors.neutral50};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${`${minimalSizeForKeepingCutoutsSettingsOnElement}px`};
  transform: ${({ cssScale }) => `scale(${1 / cssScale})`};
  transform-origin: top left;

  ${({ width, height, cssScale }) =>
    width * cssScale >= 180 &&
    height * cssScale >= 180 &&
    css`
      top: 50%;
      left: 50%;
      transform: ${`scale(${1 / cssScale}) translate(-50%, -50%)`};
      width: ${`${width * cssScale}px`};
      height: ${`${height * cssScale}px`};
    `}
`;

export const StyledInput = styled(Input)`
  margin-bottom: 1rem;

  > label {
    color: ${({ theme }) => theme.colors.neutral10};
    font-size: 1.4rem;
  }

  > input {
    padding: 0 1rem;
    line-height: 3.6rem;
    font-size: 1.4rem;
    min-width: 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 1rem 0 0;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  cursor: pointer;

  &:last-of-type {
    margin: 0;
  }
`;

export const Icon = styled.img`
  display: block;
  max-width: 100%;
`;

import React from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from 'state';

import 'bootstrap/dist/css/bootstrap.min.css';

import { App } from 'App';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme/ThemeProvider';
import reportWebVitals from './reportWebVitals';

const store = createStore(appReducer, composeWithDevTools());

Sentry.init({ dsn: 'https://62aa79e0e2684687b85d90ed96c40604@o380431.ingest.sentry.io/5863867' });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

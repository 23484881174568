import React from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'components/Input/Input';
import { Button, buttonStyleTypes } from 'components/Button/Button';

export const Counter = ({ className, label, name, id, onChange, value, increment, decrement }) => {
  return (
    <Wrapper className={className}>
      <Input label={label} name={name} id={id} onChange={onChange} value={value} />
      <Buttons>
        <StyledButton styleType={buttonStyleTypes.GREY} onClick={() => increment(+value + 1)}>
          <Icon />
        </StyledButton>
        <StyledButton styleType={buttonStyleTypes.GREY} onClick={() => decrement(+value - 1)}>
          <Icon minus />
        </StyledButton>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  padding: 0;
  width: 4rem;
  margin-left: 0.5rem;
  position: relative;
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.4rem;
  height: 1.4rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0.2rem;
    background: ${({ theme }) => theme.colors.black};
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);

    ${({ minus }) =>
      minus &&
      css`
        display: none;
      `}
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 3.2rem;
  margin-left: 0.5rem;
`;

import React from 'react';

import { Counter } from 'components/Counter/Counter';
import { formikKeys } from '../TechnicalCutoutsView.const';
import { useTechnicalCutoutsContext } from '../TechnicalCutoutsView.context';
import * as S from './FormSettingSpanSizes.styled';

export const FormSettingSpanSizes = () => {
  const { formik } = useTechnicalCutoutsContext();

  const resetElementOnSpan = () => {
    if (formik.values.elementOnSpan) {
      formik.setFieldValue(formikKeys.elementOnSpan, null);
      formik.setFieldValue(formikKeys.elementOnSpanWidth, 10);
      formik.setFieldValue(formikKeys.elementOnSpanHeight, 10);
    }
  };

  return (
    <S.Wrapper>
      <S.StyledCounter
        name={formikKeys.spanWidth}
        label="Szerokość przęsła"
        value={formik.values.spanWidth}
        onChange={e => {
          const value = +e.currentTarget.value;
          if (Number.isNaN(value)) return;

          formik.setFieldValue(formikKeys.spanWidth, value);
          resetElementOnSpan();
        }}
        increment={value => {
          formik.setFieldValue(formikKeys.spanWidth, value);
        }}
        decrement={value => {
          if (value < 0) return;
          formik.setFieldValue(formikKeys.spanWidth, value);

          if (formik.values.elementOnSpan && formik.values.elementOnSpanWidth > value) {
            formik.setFieldValue(formikKeys.elementOnSpanWidth, value);
          }
        }}
      />
      <Counter
        label="Wysokość przęsła"
        name={formikKeys.spanHeight}
        value={formik.values.spanHeight}
        onChange={e => {
          const value = +e.currentTarget.value;
          if (Number.isNaN(value)) return;

          formik.setFieldValue(formikKeys.spanHeight, value);
          resetElementOnSpan();
        }}
        increment={value => {
          formik.setFieldValue(formikKeys.spanHeight, value);
        }}
        decrement={value => {
          if (value < 0) return;
          formik.setFieldValue(formikKeys.spanHeight, value);

          if (formik.values.elementOnSpan && formik.values.elementOnSpanHeight > value) {
            formik.setFieldValue(formikKeys.elementOnSpanHeight, value);
          }
        }}
      />
    </S.Wrapper>
  );
};

import { Label } from 'components/Label/Label';
import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { theme } from 'theme/theme';

const { colors } = theme;

const styles = {
  control: (originalStyles, state) => {
    const { hasValue } = state;

    return {
      ...originalStyles,
      backgroundColor: hasValue ? colors.neutral10 : colors.primary,
      border: 'none',
      borderRadius: '2rem',
      minHeight: '4rem',
    };
  },
  valueContainer: originalStyles => ({
    ...originalStyles,
    padding: '0 1.8rem',
  }),
  placeholder: originalStyles => ({
    ...originalStyles,
    color: colors.white,
    fontSize: '1.5rem',
    fontWeight: '600',
  }),
  indicatorSeparator: () => ({
    display: 'none',
    visible: 'hidden',
  }),
  dropdownIndicator: (originalStyles, { hasValue }) => ({
    ...originalStyles,
    padding: '0 2rem',
    color: hasValue ? colors.black : colors.white,
  }),
  singleValue: originalStyles => ({
    ...originalStyles,
    color: colors.black,
  }),
  input: originalStyles => ({
    ...originalStyles,
    color: colors.black,
  }),
  menu: originalStyles => ({
    ...originalStyles,
    borderRadius: '2rem',
    overflow: 'hidden',
  }),
  menuList: originalStyles => ({
    ...originalStyles,
    padding: '1rem',
  }),
  option: (originalStyles, { isSelected }) => ({
    ...originalStyles,
    backgroundColor: isSelected ? colors.primary : colors.white,
    borderRadius: '2rem',
    paddingRight: '1.8rem',
    paddingLeft: '1.8rem',
    fontSize: '1.5rem',
  }),
};

export const Select = props => {
  const { id, label } = props;
  return (
    <Wrapper>
      <Label htmlFor={id}>{label}</Label>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ReactSelect styles={styles} {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

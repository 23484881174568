import { combineReducers } from 'redux';

const appInitialState = {
  finishingProject: false,
};

// eslint-disable-next-line default-param-last
const app = (state = appInitialState, action) => {
  switch (action.type) {
    case 'FINISH_PROJECT':
      return { ...state, finishingProject: true };
    case 'EXIT_FINISH_PROJECT':
      return { ...state, finishingProject: false };
    default:
      return state;
  }
};

const appReducer = combineReducers({
  app,
});

export default appReducer;

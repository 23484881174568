import styled, { css } from 'styled-components';

export const HoleWrapper = styled.span`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.neutral50};
  height: calc(1.3 * 40px);
  width: calc(4 * 40px);
  border-radius: calc(1.3 * 40px);
  display: block;

  ${({ topLeft, rotation }) =>
    topLeft &&
    css`
      top: 40px;
      left: 40px;
      transform: ${rotation ? 'rotate(90deg) translate(0, -100%)' : 'rotate(0)'};
      transform-origin: top left;
    `}

  ${({ topRight, rotation }) =>
    topRight &&
    css`
      top: 40px;
      right: 40px;
      transform: ${rotation ? 'rotate(90deg) translate(100%,0)' : 'rotate(0)'};
      transform-origin: top right;
    `}

    ${({ bottomLeft, rotation }) =>
    bottomLeft &&
    css`
      bottom: 40px;
      left: 40px;
      transform: ${rotation ? 'rotate(90deg) translate(-100%,0)' : 'rotate(0)'};
      transform-origin: bottom left;
    `}

    ${({ bottomRight, rotation }) =>
    bottomRight &&
    css`
      bottom: 40px;
      right: 40px;
      transform: ${rotation ? 'rotate(90deg) translate(0, 100%)' : 'rotate(0)'};
      transform-origin: bottom right;
    `}
`;

export const CenterHole = styled.div`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.neutral50};
  width: calc(1.5 * 40px);
  height: calc(1.5 * 40px);
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 2px;
    background: ${({ theme }) => theme.colors.neutral50};
    width: calc(100% + (0.25px * 40));
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 2px;
    background: ${({ theme }) => theme.colors.neutral50};
    height: calc(100% + (0.25px * 40));
  }
`;

export const SmallPlus = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(0.2 * 40px);
  height: calc(0.2 * 40px);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 1px;
    background: ${({ theme }) => theme.colors.neutral20};
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 100%;
    background: ${({ theme }) => theme.colors.neutral20};
    width: 1px;
  }

  ${({ toLeft }) =>
    toLeft &&
    css`
      left: calc(0.5px * 40);
    `}

  ${({ toRight }) =>
    toRight &&
    css`
      right: calc(0.5px * 40);
    `}
`;

export const SmallPlusHelpersVertical = styled.span`
  position: absolute;
  display: block;
  background: ${({ theme }) => theme.colors.neutral20};
  width: 1px;
  height: calc(0.5 * 40px);

  left: 50%;
  transform: translateX(-50%);

  ${({ top }) =>
    top &&
    css`
      bottom: calc(100% + (0.1 * 40px));
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      top: calc(100% + (0.1 * 40px));
    `}
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SmallPlusHelpersHorizontal = styled.span`
  position: absolute;
  display: block;
  background: ${({ theme }) => theme.colors.neutral20};
  height: 1px;
  width: calc(0.5 * 40px);
  top: 50%;
  transform: translateY(-50%);

  ${({ left }) =>
    left &&
    css`
      right: calc(100% + (0.1 * 40px));
    `}

  ${({ right }) =>
    right &&
    css`
      left: calc(100% + (0.1 * 40px));
    `}
`;

export const Dash = styled.span`
  display: block;
  width: 100%;
  height: 10%;
  background: ${({ theme }) => theme.colors.neutral50};
`;

export const SmallDash = styled.span`
  display: block;
  width: 100%;
  height: 2%;
  background: ${({ withBackground, theme }) => (withBackground ? theme.colors.neutral50 : '')};
`;

export const HorizontalLine = styled.div`
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${Dash} {
    width: 10%;
    height: 100%;
  }

  ${SmallDash} {
    width: 2%;
    height: 100%;
  }
`;

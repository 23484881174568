import React from 'react';
import styled, { css } from 'styled-components';

export const Distance = ({ width, height, cssScale, label, location }) => {
  return (
    <Line width={width} height={height} location={location}>
      <ParameterLabel cssScale={cssScale}>
        <Bold>{label}</Bold> mm
      </ParameterLabel>
    </Line>
  );
};

const Line = styled.div`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.colors.primary};
  z-index: 1;

  ${({ location }) => {
    switch (location) {
      case 'left':
        return css`
          top: 50%;
          right: 100%;
          transform: translateY(-50%);
        `;
      case 'right':
        return css`
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
        `;
      case 'top':
        return css`
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'bottom':
        return css`
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        `;
      default:
        return css``;
    }
  }}
`;

const ParameterLabel = styled.span`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 4px 10px;
  font-size: 1.4rem;
  z-index: 9999;
  transform: ${({ cssScale }) => `scale(${1 / cssScale}) translate(-50%, -50%)`};
  transform-origin: center;
`;

const Bold = styled.span`
  font-weight: 700;
`;

import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Input } from 'components/Input/Input';
import { Button } from 'components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

export const SendForm = () => {
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const finishingProject = useSelector(s => s.app.finishingProject);
  const error = useSelector(s => s.app.error);
  const message = useSelector(s => s.app.message);
  if (!finishingProject) return null;

  return (
    <Modal className="modal" tabIndex="-1" role="dialog">
      <ModalBox className="modal-dialog" role="document">
        <Inner className="modal-content">
          <HeaderContainer>
            <Header>Jeszcze jeden krok</Header>
            <Close
              type="button"
              className="btn"
              onClick={() => dispatch({ type: 'EXIT_FINISH_PROJECT' })}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Close>
          </HeaderContainer>
          <div>
            <SubHeader>
              Aby zakończyć projektowanie, wpisz Twój adres
              <br />
              email i nazwę projektu i naciśnij &quot;Wyślij projekt&quot;.
              <br />
              Możesz wcześniej sprawdzić podgląd projektu.
            </SubHeader>
            <div>
              <Row>
                <div>
                  <Input
                    type="email"
                    placeholder="Adres email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <span>{formik.touched?.email && formik.errors?.email}</span>
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="Nazwa projektu"
                    name="projectName"
                    value={formik.values.projectName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span>{formik.touched?.projectName && formik.errors?.projectName}</span>
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="Komentarz"
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span>{formik.touched?.comment && formik.errors?.comment}</span>
                </div>
              </Row>
              <SendContainer>
                <Preview
                  type="button"
                  onClick={() => {
                    formik.setFieldValue('actionType', 'download_send');
                    formik.handleSubmit();
                  }}
                >
                  {formik.isSubmitting && <FontAwesomeIcon className="spinner" icon={faSpinner} />}
                  {!formik.isSubmitting && <FontAwesomeIcon icon={faSearch} />}
                  Pobierz podgląd i wyślij
                </Preview>
                <Send type="button" onClick={formik.handleSubmit}>
                  Wyślij projekt
                </Send>
              </SendContainer>
              <ResponseContainer error={error}>
                <span>{message}</span>
                {formik.isSubmitting && <span>Trwa przesyłanie...</span>}
                {!!Object.keys(formik.errors).length && (
                  <span>...by wysłać projekt skoryguj błędy</span>
                )}
              </ResponseContainer>
            </div>
          </div>
        </Inner>
      </ModalBox>
    </Modal>
  );
};

const ModalBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Modal = styled.div`
  display: block;
  background-color: rgba(0, 0, 0, 0.9);
  .modal-dialog {
    max-width: 850px;
  }
`;

const Inner = styled.div`
  padding: 5rem;
  border-radius: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Header = styled.h1`
  font-size: 2.6rem;
  font-weight: 400;
  text-transform: uppercase;
`;

const Close = styled.button`
  font-size: 2.5rem;
  padding: 0;
`;

const SubHeader = styled.div`
  font-size: 2.6rem;
  font-weight: 300;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;

  & > div {
    width: calc(33.333% - 2rem);
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    display: inline-block;
    margin-top: 1rem;
    font-size: 1.2rem;
    margin-left: 0.6rem;
  }
`;

const Preview = styled(Button)`
  background: #0165c7;
  margin-bottom: 1rem;

  .svg-inline--fa {
    margin-right: 1rem;
  }
`;

const SendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .btn {
    width: calc(33.333% - 2rem);
    margin-bottom: 1rem;
  }
`;

const Send = styled(Button)`
  background: #44af69;
`;

const ResponseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  font-size: 1.4rem;
  color: ${props => (props.error ? '#ff0000' : 'inherit')};
`;

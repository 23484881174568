import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button, buttonStyleTypes } from 'components/Button/Button';
import { routes } from 'Router/routes';
import done from 'assets/done.svg';

export const Tabs = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Navigation>
        <NavigationList>
          {/* <NavigationItem>
            <NavigationLink to={routes.technicalCutouts} exact>
              Wycięcia techniczne
            </NavigationLink>
          </NavigationItem> */}
          <NavigationItem>
            <NavigationLink to={routes.holderPoles} exact>
              Marka do słupka
            </NavigationLink>
          </NavigationItem>
        </NavigationList>
        <Button
          type="button"
          onClick={() => dispatch({ type: 'FINISH_PROJECT' })}
          styleType={buttonStyleTypes.SECONDARY}
          icon={done}
        >
          Zakończ projektowanie
        </Button>
      </Navigation>
      <Content>
        <InnerContent>{children}</InnerContent>
      </Content>
    </>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.main`
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 42rem;
  padding: 3.5rem 0;
  border-radius: 2rem 2rem 0 0;
  margin: -2rem 0 0;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const InnerContent = styled.div`
  width: 90%;
  max-width: 1360px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;
`;

const NavigationList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const NavigationItem = styled.li`
  display: block;
  padding: 0;
  margin: 0;
`;

const NavigationLink = styled(NavLink)`
  display: block;
  padding: 1.7rem 4rem 3.7rem;
  border-radius: 2rem 2rem 0 0;
  background: ${({ theme: { colors } }) => colors.neutral40};
  color: ${({ theme: { colors } }) => colors.white};
  text-decoration: none;
  font-size: 1.6rem;

  ${({ theme: { devices } }) => css`
    ${devices.S} {
      font-size: 2rem;
    }
  `}

  &.active {
    background: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.black};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as Provider } from 'styled-components';

import { GlobalStyle } from './GlobalStyle';
import { theme } from './theme';

export const ThemeProvider = ({ children }) => (
  <Provider theme={theme}>
    <GlobalStyle />
    {children}
  </Provider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

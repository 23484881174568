import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const MainTemplate = ({ children }) => <Wrapper>{children}</Wrapper>;

MainTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

const Wrapper = styled.div`
  display: block;
  width: 90%;
  max-width: 1640px;
  margin: 0 auto;
`;

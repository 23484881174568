import React from 'react';

import {
  CenterHole,
  Dash,
  HoleWrapper,
  HorizontalLine,
  SmallDash,
  SmallPlus,
  SmallPlusHelpersHorizontal,
  SmallPlusHelpersVertical,
  VerticalLine,
} from './HolderPolesHoleSpacing.styles';

const HolePlus = ({ toLeft, toRight }) => (
  <SmallPlus toLeft={toLeft} toRight={toRight}>
    <SmallPlusHelpersVertical top />
    <SmallPlusHelpersVertical bottom />
    <SmallPlusHelpersHorizontal left />
    <SmallPlusHelpersHorizontal right />
  </SmallPlus>
);

const Hole = ({ topLeft, topRight, bottomLeft, rotation, bottomRight }) => (
  <HoleWrapper
    bottomRight={bottomRight}
    bottomLeft={bottomLeft}
    topRight={topRight}
    topLeft={topLeft}
    rotation={rotation}
  >
    <HolePlus toLeft />
    <HolePlus toRight />
  </HoleWrapper>
);

const Dashes = () => (
  <>
    <Dash />
    {[1, 2, 3, 4, 5, 6].map(sequence => (
      <React.Fragment key={sequence}>
        <SmallDash />
        <SmallDash withBackground />
        <SmallDash />
        <Dash />
      </React.Fragment>
    ))}
  </>
);

export const HolderPolesHoleSpacing = ({ holderRotation }) => (
  <>
    <Hole topLeft rotation={holderRotation} />
    <Hole topRight rotation={holderRotation} />
    <Hole bottomLeft rotation={holderRotation} />
    <Hole bottomRight rotation={holderRotation} />
    <CenterHole />
    <VerticalLine>
      <Dashes />
    </VerticalLine>
    <HorizontalLine>
      <Dashes />
    </HorizontalLine>
  </>
);

import React from 'react';

import { buttonStyleTypes } from 'components/Button/Button';
import { elementOnSpan } from '../TechnicalCutoutsView.const';
import { useTechnicalCutoutsContext } from '../TechnicalCutoutsView.context';
import * as S from './FormAddingElementToSpan.styled';

export const FormAddingElementToSpan = () => {
  const { formik, handleAddElement } = useTechnicalCutoutsContext();

  const checkIsElementSelected = elementValue =>
    formik.values.elementOnSpan !== null && formik.values.elementOnSpan === elementValue;

  const getStyleType = (isElementSelected, areOtherElementsSelectedArray) => {
    if (isElementSelected) {
      return buttonStyleTypes.PRIMARY;
    }

    if (areOtherElementsSelectedArray.includes(true)) {
      return buttonStyleTypes.NEUTRAL;
    }

    return buttonStyleTypes.SECONDARY;
  };

  const isTechnicalCutsSelected = checkIsElementSelected(elementOnSpan.TECHNICAL_CUTS);
  const isInvisibleMailboxSelected = checkIsElementSelected(elementOnSpan.INVISIBLE_MAILBOX);
  const isMailboxSelected = checkIsElementSelected(elementOnSpan.MAILBOX);

  return (
    <S.Wrapper>
      <S.StyledButton
        iconComponent={<S.Icon minus={isTechnicalCutsSelected} />}
        onClick={() => handleAddElement(elementOnSpan.TECHNICAL_CUTS)}
        disabled={isMailboxSelected || isInvisibleMailboxSelected}
        styleType={getStyleType(isTechnicalCutsSelected, [
          isInvisibleMailboxSelected,
          isMailboxSelected,
        ])}
      >
        Wycięcia techniczne
      </S.StyledButton>
      {/* <S.StyledButton
        iconComponent={() => <S.Icon minus={isMailboxSelected} />}
        onClick={() => handleAddElement(elementOnSpan.MAILBOX)}
        disabled={isTechnicalCutsSelected || isInvisibleMailboxSelected}
        styleType={getStyleType(isMailboxSelected, [
          isInvisibleMailboxSelected,
          isTechnicalCutsSelected,
        ])}
      >
        Skrzynka na listy
      </S.StyledButton> */}
      {/* <S.StyledButton
        iconComponent={() => <S.Icon minus={isInvisibleMailboxSelected} />}
        onClick={() => handleAddElement(elementOnSpan.INVISIBLE_MAILBOX)}
        disabled={isTechnicalCutsSelected || isMailboxSelected}
        styleType={getStyleType(isInvisibleMailboxSelected, [
          isTechnicalCutsSelected,
          isMailboxSelected,
        ])}
      >
        Skrzynka Invisible
      </S.StyledButton> */}
    </S.Wrapper>
  );
};

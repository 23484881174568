import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { Field } from 'Router/HolderPolesView/HolderPolesView.styles';

export const MountingHolesButton = () => {
  const formik = useFormikContext();
  const createHref = () => {
    if (formik.values.holderSizes?.value === undefined) return '';
    switch (formik.values.holderSizes?.value) {
      case '15x15':
      case '14x20':
      case '20x25':
        return `pdf/${formik.values.holderSizes?.value}.pdf`;
      default:
        throw new Error(`unknown holder size: ${formik.values.holderSizes?.value}`);
    }
  };
  const href = createHref();
  if (!href) return null;
  return (
    <Field>
      <EmptyPlace />
      <A href={href} target="_blank" rel="noreferrer" className="btn">
        Zobacz rozstaw otworów
      </A>
    </Field>
  );
};

export const EmptyPlace = styled.div`
  height: 22.5px;
  width: 100%;
  margin-bottom: 1rem;
`;

const A = styled.a`
  font-size: 1.6rem;
  background: ${({ theme: { colors } }) => colors.primary};
  border-radius: 2rem;
  color: ${({ theme: { colors } }) => colors.white};
  padding: 0.6rem 2rem;
  border: 2px solid ${({ theme: { colors } }) => colors.primary};
  &:hover {
    background: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.primary};
  }
`;

export default MountingHolesButton;

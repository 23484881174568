import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { POSITION } from './consts';

export const HolderPolesViewCreatorBoardPosition = () => {
  const formik = useFormikContext();
  const positionX = formik.values.polePosition?.x;
  const positionY = formik.values.polePosition?.y;

  const topCondition = positionY === POSITION.TOP;
  const leftCondition = positionX === POSITION.LEFT;
  const rightCondition = positionX === POSITION.RIGHT;
  const bottomCondition = positionY === POSITION.BOTTOM;

  if (formik.values.poleSizes !== null) {
    return (
      <>
        {!leftCondition && (
          <Left
            width={formik.values.parameters?.left}
            fromTop={formik.values.parameters?.top}
            crossPolesHeight={
              (formik.values.holderRotation && formik.values.polesRotation) ||
              (!formik.values.holderRotation && !formik.values.polesRotation)
                ? formik.values.poleSizes?.height
                : formik.values.poleSizes?.width
            }
          >
            <MarkHLeft>
              <strong>{formik.values.parameters?.left}</strong> mm
            </MarkHLeft>
          </Left>
        )}
        {!rightCondition && (
          <Right
            width={formik.values.parameters?.right}
            fromTop={formik.values.parameters?.top}
            crossPolesHeight={
              (formik.values.holderRotation && formik.values.polesRotation) ||
              (!formik.values.holderRotation && !formik.values.polesRotation)
                ? formik.values.poleSizes?.height
                : formik.values.poleSizes?.width
            }
          >
            <MarkHRight>
              <strong>{formik.values.parameters?.right}</strong> mm
            </MarkHRight>
          </Right>
        )}
        {!topCondition && (
          <Top
            height={formik.values.parameters?.top}
            crossPolesWidth={
              (formik.values.holderRotation && formik.values.polesRotation) ||
              (!formik.values.holderRotation && !formik.values.polesRotation)
                ? formik.values.poleSizes?.width
                : formik.values.poleSizes?.height
            }
            fromLeft={formik.values.parameters?.left}
          >
            <MarkVTop>
              <strong>{formik.values.parameters?.top}</strong> mm
            </MarkVTop>
          </Top>
        )}
        {!bottomCondition && (
          <Bottom
            height={formik.values.parameters?.bottom}
            crossPolesWidth={
              (formik.values.holderRotation && formik.values.polesRotation) ||
              (!formik.values.holderRotation && !formik.values.polesRotation)
                ? formik.values.poleSizes?.width
                : formik.values.poleSizes?.height
            }
            fromLeft={formik.values.parameters?.left}
          >
            <MarkVBottom>
              <strong>{formik.values.parameters?.bottom}</strong> mm
            </MarkVBottom>
          </Bottom>
        )}
      </>
    );
  }

  return null;
};

const Mark = styled.span`
  display: block;
  text-align: center;
  line-height: 3.7rem;
  height: 3.7rem;
  background: #808080;
  color: ${({ theme: { colors } }) => colors.white};
  border-radius: 2rem;
  font-size: 1.4rem;
  flex-shrink: 0;
`;

const MarkVTop = styled(Mark)`
  margin-top: -1.85rem;
  padding: 0 1.6rem;
`;

const MarkVBottom = styled(Mark)`
  margin-top: 1.85rem;
  padding: 0 1.6rem;
`;

const MarkHLeft = styled(Mark)`
  margin-top: -1.8rem;
  margin-left: -1.7rem;
  padding: 0 1.6rem;
`;

const MarkHRight = styled(Mark)`
  margin-top: -1.8rem;
  margin-left: 1.7rem;
  padding: 0 1.6rem;
`;

const Line = styled.span`
  display: flex;
  position: absolute;
  z-index: 1;
  background: #bfbfbf;
  justify-content: center;
`;

const Left = styled(Line)`
  justify-content: center;
  top: ${({ crossPolesHeight, fromTop }) => `${(crossPolesHeight * 40) / 2 + fromTop * 4}px`};
  left: 0;
  width: ${({ width }) => `${width * 4}px`};
  height: 2px;
`;

const Right = styled(Line)`
  justify-content: center;
  top: ${({ crossPolesHeight, fromTop }) => `${(crossPolesHeight * 40) / 2 + fromTop * 4}px`};
  right: 0;
  width: ${({ width }) => `${width * 4}px`};
  height: 2px;
`;

const Top = styled(Line)`
  align-items: center;
  top: 0;
  left: ${({ crossPolesWidth, fromLeft }) => `${(crossPolesWidth * 40) / 2 + fromLeft * 4}px`};
  height: ${({ height }) => `${height * 4}px`};
  width: 2px;
`;

const Bottom = styled(Line)`
  align-items: center;
  bottom: 0;
  left: ${({ crossPolesWidth, fromLeft }) => `${(crossPolesWidth * 40) / 2 + fromLeft * 4}px`};
  height: ${({ height }) => `${height * 4}px`};
  width: 2px;
`;

import { createConditions } from 'Router/HolderPolesView/HolderPolesViewPole.calc';
import rotateIcon from 'assets/rotate.svg';
import trashIcon from 'assets/trash.svg';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { HolderPolesViewPoleMoveButton } from './HolderPolesViewPoleMoveButton';
import { POSITION } from './consts';
import { useHolderPolesContext } from './useHolderPolesContext';

export const HolderPolesViewPole = ({
  width,
  height,
  handleTrash,
  handleRotate,
  rotation,
  positionX,
  positionY,
}) => {
  const formik = useFormikContext();
  const { calculateParameters } = useHolderPolesContext();
  const finishingProject = useSelector(s => s.app.finishingProject);
  const handleMoveHolder = (arrowPositionX, arrowPositionY) => {
    if (positionX === POSITION.CENTER && positionY === POSITION.CENTER) {
      formik.setFieldValue('polePosition', { x: arrowPositionX, y: arrowPositionY });
    }

    if (positionX === POSITION.LEFT && positionY === POSITION.TOP) {
      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.CENTER });
      }
    }

    if (positionX === POSITION.CENTER && positionY === POSITION.TOP) {
      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.CENTER });
      }
    }

    if (positionX === POSITION.RIGHT && positionY === POSITION.TOP) {
      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }
    }

    if (positionX === POSITION.LEFT && positionY === POSITION.CENTER) {
      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.BOTTOM });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.BOTTOM });
      }
    }

    if (positionX === POSITION.RIGHT && positionY === POSITION.CENTER) {
      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.TOP });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.BOTTOM });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.BOTTOM) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.BOTTOM });
      }
    }

    if (positionX === POSITION.LEFT && positionY === POSITION.BOTTOM) {
      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.BOTTOM });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }
    }

    if (positionX === POSITION.CENTER && positionY === POSITION.BOTTOM) {
      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.LEFT, y: POSITION.BOTTOM });
      }

      if (arrowPositionX === POSITION.RIGHT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.BOTTOM });
      }
    }

    if (positionX === POSITION.RIGHT && positionY === POSITION.BOTTOM) {
      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.CENTER) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.BOTTOM });
      }

      if (arrowPositionX === POSITION.CENTER && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.RIGHT, y: POSITION.CENTER });
      }

      if (arrowPositionX === POSITION.LEFT && arrowPositionY === POSITION.TOP) {
        formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
      }
    }
  };

  useEffect(() => {
    const parameters = calculateParameters({
      ...formik.values,
    });

    formik.setFieldValue('parameters', parameters);
  }, [formik.values.polePosition]);
  const poleWidth = rotation ? height : width;
  const poleHeight = rotation ? width : height;
  const conditions = createConditions(positionX, positionY);

  return (
    <Wrapper width={poleWidth} height={poleHeight} posX={positionX} posY={positionY}>
      {!finishingProject && conditions.leftTop && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.LEFT}
          positionY={POSITION.TOP}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.top && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.CENTER}
          positionY={POSITION.TOP}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.rightTop && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.RIGHT}
          positionY={POSITION.TOP}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.left && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.LEFT}
          positionY={POSITION.CENTER}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.right && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.RIGHT}
          positionY={POSITION.CENTER}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.leftBottom && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.LEFT}
          positionY={POSITION.BOTTOM}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.bottom && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.CENTER}
          positionY={POSITION.BOTTOM}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      {!finishingProject && conditions.rightBottom && (
        <HolderPolesViewPoleMoveButton
          positionX={POSITION.RIGHT}
          positionY={POSITION.BOTTOM}
          handleMoveHolder={handleMoveHolder}
        />
      )}
      <InnerWrapper>
        <Title id="handle">Słupek</Title>
        <Settings>
          {!finishingProject && (
            <Button type="button" onClick={handleTrash}>
              <Icon src={trashIcon} alt="usuń" />
            </Button>
          )}
          {!finishingProject && width !== height && (
            <Button type="button" onClick={handleRotate}>
              <Icon src={rotateIcon} alt="obróć" />
            </Button>
          )}
        </Settings>
      </InnerWrapper>
    </Wrapper>
  );
};

HolderPolesViewPole.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  handleTrash: PropTypes.func.isRequired,
  handleRotate: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: ${({ width }) => `${width * 40}px`};
  height: ${({ height }) => `${height * 40}px`};
  background: ${({ theme: { colors } }) => colors.neutral50};
  color: ${({ theme: { colors } }) => colors.neutral30};
  display: flex;
  flex-direction: column;

  ${({ posX, posY }) => {
    switch (true) {
      case posX === POSITION.LEFT && posY === POSITION.TOP:
        return css`
          top: 0;
          left: 0;
        `;
      case posX === POSITION.LEFT && posY === POSITION.CENTER:
        return css`
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        `;
      case posX === POSITION.LEFT && posY === POSITION.BOTTOM:
        return css`
          bottom: 0;
          left: 0;
        `;
      case posX === POSITION.CENTER && posY === POSITION.TOP:
        return css`
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        `;
      case posX === POSITION.CENTER && posY === POSITION.CENTER:
        return css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `;
      case posX === POSITION.CENTER && posY === POSITION.BOTTOM:
        return css`
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        `;
      case posX === POSITION.RIGHT && posY === POSITION.TOP:
        return css`
          top: 0;
          right: 0;
        `;
      case posX === POSITION.RIGHT && posY === POSITION.CENTER:
        return css`
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        `;
      case posX === POSITION.RIGHT && posY === POSITION.BOTTOM:
        return css`
          bottom: 0;
          right: 0;
        `;
      default:
        return '';
    }
  }}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Title = styled.h3`
  margin: 0;
  padding: 2rem 0 0;
  font-size: 3rem;
  font-weight: 300;
`;

const Settings = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 1.5rem;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 1rem;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  cursor: pointer;
`;

const Icon = styled.img`
  display: block;
  max-width: 100%;
`;

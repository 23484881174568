import styled from 'styled-components';
import { Counter } from 'components/Counter/Counter';

export const Wrapper = styled.div`
  display: flex;
`;

export const StyledCounter = styled(Counter)`
  margin-right: 2rem;
`;

import axios from 'axios';
import { Button } from 'components/Button/Button';
import { FormikProvider, useFormik } from 'formik';
import * as htmlToImage from 'html-to-image';
import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import Pdf from 'Pdf/Pdf';
import generatePdf from 'Pdf/generatePdf';
import wrapPdfWithHtml from 'Pdf/wrapPdfWithHtml';
import {
  Column,
  ErrorMessage,
  Field,
  Form,
  Row,
  Wrapper,
} from 'Router/HolderPolesView/HolderPolesView.styles';
import rotateIcon from 'assets/rotate.svg';
import { Input } from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { Select } from 'components/Select/Select';
import { PageTemplate } from 'templates/PageTemplate';
import { HolderPolesViewCreator } from './HolderPolesViewCreator';
import { MountingHolesButton } from './MountingHolesButton';
import { POSITION } from './consts';
import { HolderPolesContext } from './useHolderPolesContext';

const pole6_4 = { value: '6x4', label: '60 x 40 mm', width: 6, height: 4 };
const pole6_8 = { value: '8x6', label: '80 x 60 mm', width: 6, height: 8 };
const pole8_8 = { value: '8x8', label: '80 x 80 mm', width: 8, height: 8 };
const pole10_4 = { value: '10x4', label: '100 x 40 mm', width: 10, height: 4 };
const pole10_10 = { value: '10x10', label: '100 x 100 mm', width: 10, height: 10 };
const pole12_12 = { value: '12x12', label: '120 x 120 mm', width: 12, height: 12 };
const pole15_15 = { value: '15x15', label: '150 x 150 mm', width: 15, height: 15 };

const holderSizesOptions = [
  {
    value: '15x15',
    label: '150 x 150 mm',
    width: 15,
    height: 15,
    poles: [pole6_4, pole6_8, pole8_8, pole10_4, pole10_10],
  },
  {
    value: '14x20',
    label: '140 x 200 mm',
    width: 14,
    height: 20,
    poles: [pole6_4, pole6_8, pole8_8, pole10_4, pole10_10],
  },
  {
    value: '20x25',
    label: '200 x 250 mm',
    width: 20,
    height: 25,
    poles: [pole6_4, pole6_8, pole8_8, pole10_4, pole10_10, pole12_12, pole15_15],
  },
];

const initialValues = {
  holderSizes: null,
  poleSizes: null,
  parameters: null,
  polesRotation: false,
  holderRotation: false,
  postHeight: 0,
  postQuantity: 1,
  polePosition: { x: POSITION.CENTER, y: POSITION.CENTER },
  actionType: '',
  projectName: '',
  comment: '',
  email: '',
};

const moreThan0 = 'Wartość powinna być większa od 0';
const integer = 'Wartość musi być liczbą całkowitą';
const required = 'Wartość wymagana';

const validationSchema = yup.object().shape({
  projectName: yup.string().max(255).required('Proszę podać nazwę projektu.'),
  email: yup.string().email('Adres email jest niepoprawny.').required('Proszę podać email.'),
  postHeight: yup
    .number()
    .integer(integer)
    .positive(moreThan0)
    .min(500, 'Minimalna wartość to 500 mm')
    .max(3500, 'Maksymalna wartość to 3500 mm')
    .required(required),
  postQuantity: yup
    .number()
    .integer(integer)
    .positive(moreThan0)
    .min(1, moreThan0)
    .required(required),
  comment: yup.string().max(255),
});

export const HolderPolesView = () => {
  const [finalMessage, setFinalMessage] = useState('');
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      if (values.polePosition === null) {
        formik.setSubmitting(false);
        return;
      }

      const board = document.querySelector('#board');
      htmlToImage
        .toJpeg(board, { backgroundColor: '#fff' })
        .then(boardImg => {
          const pdf = ReactDOMServer.renderToStaticMarkup(
            <Pdf values={formik.values} boardImg={boardImg} />,
          );
          const html = wrapPdfWithHtml(pdf);
          // keep for debugging
          // window.open().document.write(html);
          generatePdf(html).then(url => {
            if (values.actionType === 'download_send') {
              window.open(url);
            }

            axios
              .post('https://api.my.konsport.com/wyciecia/projects', {
                id: uuidv4(),
                email: values.email,
                pdfUrl: url,
                name: values.projectName,
                comment: values.comment,
              })
              .then(() => {
                setFinalMessage('Twoja wiadomość została wysłana.');
              })
              .catch(() => {
                setError('Wystąpił problem, skontaktuj się z Konsport.');
              })
              .finally(() => {
                formik.setSubmitting(false);
              });
          });
        })
        .catch(() => {
          setError('Wystąpił problem, skontaktuj się z Konsport.');
        });
    },
  });

  const calculateParameters = ({
    holderRotation,
    poleSizes,
    polePosition,
    holderSizes,
    polesRotation,
  }) => {
    if (polePosition === null || poleSizes === null || holderSizes === null) {
      return null;
    }

    const parameters = {};

    let holderSizeWidth = holderSizes.width;
    let holderSizeHeight = holderSizes.height;
    let crossPolesWidth = poleSizes.width;
    let crossPolesHeight = poleSizes.height;

    if (holderRotation && polesRotation) {
      holderSizeWidth = holderSizes.height;
      holderSizeHeight = holderSizes.width;
      crossPolesWidth = poleSizes.width;
      crossPolesHeight = poleSizes.height;
    } else if (holderRotation && !polesRotation) {
      holderSizeWidth = holderSizes.height;
      holderSizeHeight = holderSizes.width;
      crossPolesWidth = poleSizes.height;
      crossPolesHeight = poleSizes.width;
    } else if (!holderRotation && polesRotation) {
      holderSizeWidth = holderSizes.width;
      holderSizeHeight = holderSizes.height;
      crossPolesWidth = poleSizes.height;
      crossPolesHeight = poleSizes.width;
    }

    const emptySpaceOnX = holderSizeWidth - crossPolesWidth;
    const emptySpaceOnY = holderSizeHeight - crossPolesHeight;

    if (polePosition.x === POSITION.LEFT) {
      parameters.left = 0;
      parameters.right = emptySpaceOnX * 10;
    } else if (polePosition.x === POSITION.RIGHT) {
      parameters.right = 0;
      parameters.left = emptySpaceOnX * 10;
    } else if (polePosition.x === POSITION.CENTER) {
      parameters.left = (emptySpaceOnX / 2) * 10;
      parameters.right = (emptySpaceOnX / 2) * 10;
    }

    if (polePosition.y === POSITION.TOP) {
      parameters.top = 0;
      parameters.bottom = emptySpaceOnY * 10;
    } else if (polePosition.y === POSITION.BOTTOM) {
      parameters.top = emptySpaceOnY * 10;
      parameters.bottom = 0;
    } else if (polePosition.y === POSITION.CENTER) {
      parameters.top = (emptySpaceOnY / 2) * 10;
      parameters.bottom = (emptySpaceOnY / 2) * 10;
    }

    return parameters;
  };

  const handlePoleTrash = () => {
    formik.setFieldValue('poleSizes', null);
    formik.setFieldValue('polePosition', { x: POSITION.CENTER, y: POSITION.CENTER });
    formik.setFieldValue('polesRotation', false);
  };

  const handlePoleRotation = formikProps => {
    const parameters = calculateParameters({
      ...formikProps.values,
      polesRotation: !formikProps.values.polesRotation,
    });

    formikProps.setFieldValue('parameters', parameters);
    formikProps.setFieldValue('polesRotation', !formikProps.values.polesRotation);
  };

  const handleHolderRotation = formikProps => {
    const parameters = calculateParameters({
      ...formikProps.values,
      holderRotation: !formikProps.values.holderRotation,
    });

    formikProps.setFieldValue('parameters', parameters);
    formikProps.setFieldValue('holderRotation', !formikProps.values.holderRotation);
  };

  const handleHolderSizes = option => {
    const parameters = calculateParameters({
      ...formik.values,
      holderSizes: option,
    });

    formik.setValues(initialValues);
    formik.setFieldValue('parameters', parameters);
    formik.setFieldValue('holderSizes', option);
  };

  const handlePoleSizes = option => {
    const parameters = calculateParameters({
      ...formik.values,
      poleSizes: option,
      polesRotation: false,
    });

    formik.setFieldValue('poleSizes', option);
    formik.setFieldValue('polesRotation', false);
    formik.setFieldValue('parameters', parameters);
  };

  const getPoleOptions = () =>
    holderSizesOptions.find(({ value }) => formik.values.holderSizes.value === value)?.poles || [];

  const value = useMemo(
    () => ({ handlePoleTrash, handlePoleRotation, calculateParameters }),
    [handlePoleTrash, handlePoleRotation, calculateParameters],
  );

  const handleChangeNumber = event => {
    const { value: fieldValue, name } = event.currentTarget;
    if (Number.isNaN(+fieldValue)) return;
    formik.setFieldValue(name, String(+fieldValue));
  };

  return (
    <HolderPolesContext.Provider value={value}>
      <FormikProvider value={formik}>
        <PageTemplate>
          <Wrapper>
            {!!finalMessage && <Modal close={() => setFinalMessage('')} message={finalMessage} />}
            {!!error && <Modal close={() => setError('')} message={error} />}
            <Form>
              <Row>
                <Column>
                  <Field>
                    <Select
                      id="holder_sizes"
                      name="holder_sizes"
                      label="Wymiary marki"
                      placeholder="Wybierz"
                      onChange={handleHolderSizes}
                      options={holderSizesOptions}
                      value={formik.values.holderSizes}
                    />
                  </Field>
                  {formik.values.holderSizes !== null && (
                    <Field>
                      <Select
                        id="cross_poles"
                        name="cross_poles"
                        placeholder="Wybierz"
                        label="Marka do słupka"
                        options={getPoleOptions()}
                        value={formik.values.poleSizes}
                        onChange={handlePoleSizes}
                      />
                    </Field>
                  )}
                  {formik.values.poleSizes !== null && <MountingHolesButton />}
                </Column>
                {formik.values.holderSizes !== null && (
                  <Column>
                    <Field>
                      <Button
                        type="button"
                        icon={rotateIcon}
                        onClick={() => handleHolderRotation(formik)}
                      >
                        Obróć markę
                      </Button>
                    </Field>
                  </Column>
                )}
              </Row>
              {formik.values.poleSizes !== null && (
                <Row>
                  <Column>
                    <Field>
                      <Input
                        id="post_height"
                        name="postHeight"
                        label="Wysokość słupka (mm)"
                        value={formik.values.postHeight}
                        onChange={handleChangeNumber}
                      />
                      <ErrorMessage>{formik.errors.postHeight}</ErrorMessage>
                    </Field>
                    <Field>
                      <Input
                        id="post_quantity"
                        name="postQuantity"
                        label="Ilość słupków"
                        value={formik.values.postQuantity}
                        onChange={handleChangeNumber}
                      />
                      <ErrorMessage>{formik.errors.postQuantity}</ErrorMessage>
                    </Field>
                  </Column>
                </Row>
              )}
            </Form>
            <HolderPolesViewCreator />
          </Wrapper>
        </PageTemplate>
      </FormikProvider>
    </HolderPolesContext.Provider>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const Section = ({ title, children, className }) => (
  <Wrapper className={className}>
    <Title>{title}</Title>
    {children}
  </Wrapper>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.defaultProps = {
  className: '',
};

const Wrapper = styled.section`
  display: block;
  padding: 3rem 4rem;
  border-radius: 0 0 0 2rem;
  margin: 4rem 0 0;
  background: ${({ theme: { colors } }) => colors.neutral40};

  ${({ theme: { devices } }) => css`
    ${devices.S} {
      padding: 4rem 5rem;
    }

    ${devices.M} {
      padding: 5rem 6rem;
    }
  `}
`;

const Title = styled.h2`
  display: block;
  font-size: 3rem;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.white};
  margin: 0 0 2.5rem;
`;

import axios from 'axios';

const generatePdf = html => {
  // open pdf html in new tab
  // window.open().document.write(html);

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-promise-executor-return
    return axios
      .request('https://www.api.pdf.konsport.com', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/html; charset=utf-8',
          'X-Project-Name': 'wyciecia',
          'X-Pdf-Name': 'projekt-marki',
          'X-Include-Footer': '1',
          'X-Include-Page-Number': '0',
        },
        data: html,
      })
      .then(({ data }) => resolve(data.url))
      .catch(err => reject(err));
  });
};

export default generatePdf;

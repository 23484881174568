import React from 'react';
import styled, { css } from 'styled-components';

import { POSITION } from './consts';

export const HolderPolesViewPoleMoveButton = ({ positionX, positionY, handleMoveHolder }) => (
  <Button
    onClick={() => handleMoveHolder(positionX, positionY)}
    positionX={positionX}
    positionY={positionY}
    type="button"
  >
    <Arrow />
    <Arrowhead />
  </Button>
);

const Button = styled.button`
  position: absolute;
  z-index: 9999;
  display: block;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  border: none;
  background: ${({ theme }) => theme.colors.primary};

  ${({ positionX, positionY }) => {
    switch (true) {
      case positionX === POSITION.LEFT && positionY === POSITION.TOP:
        return css`
          left: 0;
          top: 0;
          transform: translate(-50%, -50%) rotate(-45deg);
        `;
      case positionX === POSITION.CENTER && positionY === POSITION.TOP:
        return css`
          left: 50%;
          top: 0;
          transform: translate(-50%, -50%) rotate(0);
        `;
      case positionX === POSITION.RIGHT && positionY === POSITION.TOP:
        return css`
          right: 0;
          top: 0;
          transform: translate(50%, -50%) rotate(45deg);
        `;
      case positionX === POSITION.LEFT && positionY === POSITION.CENTER:
        return css`
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-90deg);
        `;
      case positionX === POSITION.RIGHT && positionY === POSITION.CENTER:
        return css`
          right: 0;
          top: 50%;
          transform: translate(50%, -50%) rotate(90deg);
        `;
      case positionX === POSITION.LEFT && positionY === POSITION.BOTTOM:
        return css`
          left: 0;
          bottom: 0;
          transform: translate(-50%, 50%) rotate(-135deg);
        `;
      case positionX === POSITION.CENTER && positionY === POSITION.BOTTOM:
        return css`
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 50%) rotate(180deg);
        `;
      case positionX === POSITION.RIGHT && positionY === POSITION.BOTTOM:
        return css`
          right: 0;
          bottom: 0;
          transform: translate(50%, 50%) rotate(135deg);
        `;
      default:
        return css``;
    }
  }}
`;

const Arrow = styled.span`
  display: block;
  width: 0.2rem;
  height: 1.2rem;
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Arrowhead = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -0.5rem) rotate(45deg);
  border-right: none;
  border-bottom: none;
`;

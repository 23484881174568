import styled, { css } from 'styled-components';
import { Button } from 'components/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 32px;
`;

export const StyledButton = styled(Button)`
  padding-left: 16px;
  margin-left: 10px;
`;

export const Icon = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 18px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 2px;
    width: 12px;
    background: ${({ theme }) => theme.colors.white};
  }

  &::before {
    transform: translate(-50%, -50%);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);

    ${({ minus }) =>
      minus &&
      css`
        display: none;
      `}
  }
`;

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   *, *::before, *::after {
      box-sizing: border-box;
   }

   html {
      font-size: 10px;
      line-height: 1.35;
      overflow-y: scroll;
   }

   body {
      background-color: ${({ theme }) => theme.colors.neutral50};
      font-family: 'Open Sans', sans-serif;
      font-size: 1.6rem;
   }

   ::-webkit-scrollbar {
      width: 8px;
   }

   ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.neutral30};
   }

   ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.neutral10};
   }

   ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.neutral20};
   }
`;

import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { routes } from 'Router/routes';
import { Section } from 'components/Section/Section';

export const Information = () => {
  const l = useLocation();
  return (
    <StyledSection title="Informacja">
      {routes.technicalCutouts === l.pathname && (
        <P>
          Otwór techniczny jest powiększany o <strong>2 cm</strong> z każdej strony. W przypadku
          PS004 o <strong>1 cm</strong>.
        </P>
      )}
      {routes.holderPoles === l.pathname && (
        <P>
          Po zakończeniu projektowania wygeneruj dokument PDF, a następnie załącz go do zamówienia.
        </P>
      )}
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  width: 100%;
  border-radius: 0 0 0 2rem;
`;

const P = styled.p`
  display: block;
  margin: 0;
  padding-right: 6rem;
  font-size: 1.5rem;
  color: ${({ theme: { colors } }) => colors.white};

  > strong {
    font-weight: 700;
  }
`;

import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Form = styled.form`
  margin: 0 0 0.6rem;
`;

export const Field = styled.div`
  min-width: 21rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  display: inline-block;
  margin-top: 1rem;
  font-size: 1.2rem;
  margin-left: 0.6rem;
`;

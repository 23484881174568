import background from 'assets/background-light.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const HolderPolesViewBoard = ({ width, height, children }) => (
  <Wrapper width={width} height={height}>
    {children}
  </Wrapper>
);

HolderPolesViewBoard.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

HolderPolesViewBoard.defaultProps = {
  children: null,
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: content-box;
  width: ${({ width }) => `${width * 40}px`};
  height: ${({ height }) => `${height * 40}px`};
  border: 1px solid #c0c0c0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.white};
    background-image: url(${background});
  }
`;

import React from 'react';
import styled from 'styled-components';

export const Modal = ({ message, close }) => (
  <Wrapper>
    {message}
    <Button onClick={close} />
  </Wrapper>
);

const Wrapper = styled.div`
  z-index: 99999;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50%;
  max-width: 400px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 20px;
`;

const Button = styled.button`
  position: absolute;
  display: block;
  top: 0;
  width: 30px;
  height: 30px;
  left: calc(100% + 10px);
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  background: #fff;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 16px;
    height: 2px;
    background: ${({ theme }) => theme.colors.black};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 16px;
    height: 2px;
    background: ${({ theme }) => theme.colors.black};
  }
`;

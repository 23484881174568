const wrapPdfWithHtml = body => `
  <html lang="pl">
    <head>
      <meta charset="utf-8">
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous" />
      <style>
        h1 {
          margin: 2rem 0;
        }
        .boardImg {
          width: 100%;
          height: auto;
        }
      </style>
    </head>
    <body>
      ${body}
    </body>
  </html>
`;

export default wrapPdfWithHtml;

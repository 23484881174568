export const theme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    primary: '#E50019',
    secondary: '#44AF69',
    neutral10: '#E5E5E5',
    neutral20: '#96999A',
    neutral30: '#616567',
    neutral40: '#424749',
    neutral50: '#2D3234',
  },
  devices: {
    XS: `@media (min-width: 1200px)`,
    S: `@media (min-width: 1366px)`,
    M: `@media (min-width: 1600px)`,
    L: `@media (min-width: 1800px)`,
    XL: `@media (min-width: 1920px)`,
  },
};

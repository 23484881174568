import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { HolderPolesView } from './HolderPolesView/HolderPolesView';
import { TechnicalCutoutsView } from './TechnicalCutoutsView/TechnicalCutoutsView';
import { routes } from './routes';

export const Root = () => (
  <Router>
    <Routes>
      <Route exact path={routes.technicalCutouts} element={<TechnicalCutoutsView />} />
      <Route exact path={routes.holderPoles} element={<HolderPolesView />} />
    </Routes>
  </Router>
);

import React from 'react';

export const TechnicalCutoutsPdf = ({ values, boardImg }) => (
  <div className="new-page">
    <div className="container">
      <div className="row">
        <div className="col">{values.projectName && <h1>Projekt: {values.projectName}</h1>}</div>
      </div>
    </div>
    <div>
      <div>
        <img src={boardImg} className="boardImg" alt="" />
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col">
          <div>
            <p>Typ: {values.elementOnSpan === 'TECHNICAL_CUTS' ? 'Wycięcie techniczne' : '-'}</p>
          </div>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Od lewej krawędzi</th>
                  <th>Od górnej krawędzi</th>
                  <th>Od prawej krawędzi</th>
                  <th>Od dolnej krawędzi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{values.distances.left} mm</td>
                  <td>{values.distances.top} mm</td>
                  <td>{values.distances.right} mm</td>
                  <td>{values.distances.bottom} mm</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p>Komentarz: {values.comment || '-'}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

import React from 'react';

const Pdf = ({ values, boardImg }) => (
  <div className="new-page">
    <div className="container">
      <div className="row">
        <div className="col">{values.projectName && <h1>Projekt: {values.projectName}</h1>}</div>
      </div>
    </div>
    <div>
      <div>
        <img src={boardImg} className="boardImg" alt="" />
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col">
          <div>
            <p>Słupek: {values.poleSizes.label}</p>
            <p>Mark do słupka: {values.holderSizes.label}</p>
            <p>Wysokość słupka: {values.postHeight} mm</p>
            <p>Ilość słupków: {values.postQuantity}</p>
          </div>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Od lewej krawędzi</th>
                  <th>Od górnej krawędzi</th>
                  <th>Od prawej krawędzi</th>
                  <th>Od dolnej krawędzi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{values.parameters.left} mm</td>
                  <td>{values.parameters.top} mm</td>
                  <td>{values.parameters.right} mm</td>
                  <td>{values.parameters.bottom} mm</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p>Komentarz: {values.comment || '-'}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Pdf;

import React from 'react';
import styled, { css } from 'styled-components';
import { Logo } from 'components/Logo/Logo';

export const Header = () => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <Title>Kreator marki do słupka</Title>
  </Wrapper>
);

const Wrapper = styled.header`
  display: block;
`;
const LogoWrapper = styled.div`
  padding: 3.9rem 0 3.4rem;

  ${({ theme: { devices } }) => css`
    ${devices.S} {
      padding: 4.9rem 0 4.4rem;
    }
  `}
`;

const Title = styled.h1`
  display: block;
  padding: 0;
  margin: 0 0 3.8rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 3rem;
  font-weight: 700;

  ${({ theme: { devices } }) => css`
    ${devices.S} {
      font-size: 4rem;
      margin: 0 0 4.8rem;
    }
  `}
`;

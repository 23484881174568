import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import * as htmlToImage from 'html-to-image';
import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';

import { TechnicalCutoutsPdf } from 'Pdf/TechnicalCutoutsPdf';
import generatePdf from 'Pdf/generatePdf';
import wrapPdfWithHtml from 'Pdf/wrapPdfWithHtml';
import { Modal } from 'components/Modal/Modal';
import { PageTemplate } from 'templates/PageTemplate';
import { Board } from './Board';
import { Form } from './Form';
import { formikKeys, initialValues, validationSchema } from './TechnicalCutoutsView.const';
import { TechnicalCutoutsContext } from './TechnicalCutoutsView.context';

export const TechnicalCutoutsView = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      setFailureMessage('');

      if (values.elementOnSpan === null || values.spanWidth < 10 || values.spanHeight < 10) {
        setFailureMessage('Dokończ wycięcia techniczne przed wysłaniem.');
        formik.setSubmitting(false);
        return;
      }

      const board = document.querySelector('#board');

      htmlToImage
        .toJpeg(board, { backgroundColor: '#fff' })
        .then(boardImg => {
          const pdf = ReactDOMServer.renderToStaticMarkup(
            <TechnicalCutoutsPdf values={formik.values} boardImg={boardImg} />,
          );
          const html = wrapPdfWithHtml(pdf);

          generatePdf(html).then(url => {
            if (values.actionType === 'download_send') {
              window.open(url);
            }

            axios
              .post('https://api.my.konsport.com/wyciecia/projects', {
                id: uuidv4(),
                email: values.email,
                pdfUrl: url,
                name: values.projectName,
                comment: values.comment,
              })
              .then(() => {
                setSuccessMessage('Twoja wiadomość została wysłana.');
              })
              .catch(() => {
                setFailureMessage('Wystąpił problem, skontaktuj się z Konsport.');
              })
              .finally(() => {
                formik.setSubmitting(false);
              });
          });
        })
        .catch(() => {
          setFailureMessage('Wystąpił problem, skontaktuj się z Konsport.');
        });
    },
  });

  const handleAddElement = elementOnSpan => {
    const isElementSelected = formik.values.elementOnSpan === elementOnSpan;
    const elementOnSpanValue = isElementSelected ? null : elementOnSpan;
    const elementOnSpanSizeValue = isElementSelected ? 0 : 10;

    formik.setFieldValue(formikKeys.elementOnSpan, elementOnSpanValue);
    formik.setFieldValue(formikKeys.elementOnSpanWidth, elementOnSpanSizeValue);
    formik.setFieldValue(formikKeys.elementOnSpanHeight, elementOnSpanSizeValue);
  };

  const value = useMemo(() => ({ handleAddElement, formik }), [handleAddElement, formik]);

  return (
    <FormikProvider value={formik}>
      {!!successMessage && <Modal close={() => setSuccessMessage('')} message={successMessage} />}
      {!!failureMessage && <Modal close={() => setFailureMessage('')} message={failureMessage} />}
      <PageTemplate>
        <TechnicalCutoutsContext.Provider value={value}>
          <Form />
          <Board />
        </TechnicalCutoutsContext.Provider>
      </PageTemplate>
    </FormikProvider>
  );
};

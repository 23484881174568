import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import moveIcon from 'assets/move.svg';
import trashIcon from 'assets/trash.svg';
import { Distance } from 'components/Distance/Distance';

import { useTechnicalCutoutsContext } from '../TechnicalCutoutsView.context';
import * as S from './TechnicalCutoutsViewElementCutouts.styled';

export const TechnicalCutoutsViewElementCutouts = ({ scale, cssScale }) => {
  const { formik } = useTechnicalCutoutsContext();
  const nodeRef = useRef(null);

  const [state, setState] = useState({ x: 0, y: 0 });
  const [distances, setDistances] = useState({ left: 0, right: 0, top: 0, bottom: 0 });

  const handleTrash = () => {
    formik.setFieldValue('elementOnSpan', null);
    formik.setFieldValue('elementOnSpanWidth', 10);
    formik.setFieldValue('elementOnSpanHeight', 10);
  };

  const heightWithScale = scale * +formik.values.elementOnSpanHeight;
  const widthWithScale = scale * +formik.values.elementOnSpanWidth;

  const calculateDistances = (x, y) => {
    const spanWidthWithScale = formik.values.spanWidth * scale;
    const spanHeightWithScale = formik.values.spanHeight * scale;

    const left = (spanWidthWithScale / 2 - widthWithScale / 2 + x) / scale;
    const right = (spanWidthWithScale / 2 - widthWithScale / 2 - x) / scale;
    const top = (spanHeightWithScale / 2 - heightWithScale / 2 + y) / scale;
    const bottom = (spanHeightWithScale / 2 - heightWithScale / 2 - y) / scale;

    setDistances({ left, right, top, bottom });
    formik.setFieldValue('distances', { left, right, top, bottom });
  };

  const handleDrag = (e, ui) => {
    const x = state.x + ui.deltaX;
    const y = state.y + ui.deltaY;

    setState({ x, y });
    calculateDistances(x, y);
  };

  useEffect(() => {
    calculateDistances(state.x, state.y);
  }, [
    formik.values.spanHeight,
    formik.values.spanWidth,
    formik.values.elementOnSpanHeight,
    formik.values.elementOnSpanWidth,
  ]);

  useEffect(() => {
    setState({ x: 0, y: 0 });
    calculateDistances(0, 0);
  }, [formik.values.elementOnSpanWidth, formik.values.elementOnSpanHeight]);

  return (
    <Draggable
      bounds="parent"
      handle="#handle"
      nodeRef={nodeRef}
      grid={[scale, scale]}
      onDrag={handleDrag}
      position={state}
    >
      <S.Wrapper height={heightWithScale} width={widthWithScale} ref={nodeRef}>
        <Distance
          width={distances.left * scale}
          cssScale={cssScale}
          height={cssScale < 0.6 ? 4 : 2}
          label={distances.left}
          location="left"
        />
        <Distance
          width={distances.right * scale}
          cssScale={cssScale}
          height={cssScale < 0.6 ? 4 : 2}
          label={distances.right}
          location="right"
        />
        <Distance
          width={cssScale < 0.6 ? 4 : 2}
          cssScale={cssScale}
          height={distances.top * scale}
          label={distances.top}
          location="top"
        />
        <Distance
          width={cssScale < 0.6 ? 4 : 2}
          cssScale={cssScale}
          height={distances.bottom * scale}
          label={distances.bottom}
          location="bottom"
        />
        <S.InnerWrapper height={heightWithScale} width={widthWithScale} cssScale={cssScale}>
          <div>
            <S.StyledInput
              label="Szerokość"
              id="elementOnSpanWidth"
              name="elementOnSpanWidth"
              value={+formik.values.elementOnSpanWidth}
              onBlur={e => {
                const { value } = e.target;

                if (+value < 10) {
                  formik.setFieldValue('elementOnSpanWidth', 10);
                }
              }}
              onChange={e => {
                const { value } = e.target;

                if (value > formik.values.spanWidth) {
                  formik.setFieldValue('elementOnSpanWidth', formik.values.spanWidth);
                } else {
                  formik.setFieldValue('elementOnSpanWidth', +value);
                }
              }}
              row
            />
            <S.StyledInput
              label="Wysokość"
              id="elementOnSpanHeight"
              name="elementOnSpanHeight"
              value={+formik.values.elementOnSpanHeight}
              onBlur={e => {
                const { value } = e.target;

                if (+value < 10) {
                  formik.setFieldValue('elementOnSpanHeight', 10);
                }
              }}
              onChange={e => {
                const { value } = e.target;

                if (value > formik.values.spanHeight) {
                  formik.setFieldValue('elementOnSpanHeight', formik.values.spanHeight);
                } else {
                  formik.setFieldValue('elementOnSpanHeight', +value);
                }
              }}
              row
            />
          </div>
          <S.Settings>
            <S.Button type="button" onClick={handleTrash}>
              <S.Icon src={trashIcon} alt="usuń" />
            </S.Button>
            <S.Move id="handle" image={moveIcon} />
          </S.Settings>
        </S.InnerWrapper>
      </S.Wrapper>
    </Draggable>
  );
};

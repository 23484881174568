import React, { useEffect, useState } from 'react';

import { Message } from 'components/Message/Message';
import { TechnicalCutoutsViewSpan } from '../TechnicalCutoutsViewSpan';
import { TechnicalCutoutsViewElementOnSpan } from '../TechnicalCutoutsViewElementOnSpan';
import { getCurrentScale, getCssCurrentScale, spanSizes } from './Board.const';
import { useTechnicalCutoutsContext } from '../TechnicalCutoutsView.context';
import * as S from './Board.styled';

export const Board = () => {
  const { formik } = useTechnicalCutoutsContext();
  const [boardScale, setBoardScale] = useState(1);
  const [boardCssScale, setBoardCssScale] = useState(1);

  const { spanWidth } = formik.values;
  const { spanHeight } = formik.values;

  useEffect(() => {
    setBoardScale(getCurrentScale(spanWidth, spanHeight));
    setBoardCssScale(getCssCurrentScale(spanWidth, spanHeight));
  }, [spanWidth, spanHeight]);

  return (
    <div id="board">
      <S.Wrapper cssScale={boardCssScale}>
        {formik.values.spanWidth >= spanSizes.minWidth &&
        formik.values.spanWidth <= spanSizes.maxWidth &&
        formik.values.spanHeight >= spanSizes.minHeight &&
        formik.values.spanHeight <= spanSizes.maxHeight ? (
          <TechnicalCutoutsViewSpan
            width={spanWidth}
            height={spanHeight}
            scale={boardScale}
            cssScale={boardCssScale}
          >
            {formik.values.spanWidth >= formik.values.elementOnSpanWidth &&
              formik.values.spanHeight >= formik.values.elementOnSpanHeight && (
                <TechnicalCutoutsViewElementOnSpan
                  element={formik.values.elementOnSpan}
                  cssScale={boardCssScale}
                  scale={boardScale}
                />
              )}
          </TechnicalCutoutsViewSpan>
        ) : (
          <Message lines={['Uzupełnij wymiary przęsła', 'aby rozpocząć projektowanie']} />
        )}
      </S.Wrapper>
    </div>
  );
};

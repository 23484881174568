import { Label } from 'components/Label/Label';
import React from 'react';
import styled, { css } from 'styled-components';

export const Input = ({ label, asWhite, row, className, id, ...rest }) => (
  <Wrapper className={className} row={row}>
    <Label htmlFor={id} asWhite={asWhite}>
      {label}
    </Label>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Field id={id} {...rest} asWhite={asWhite} />
  </Wrapper>
);

const Field = styled.input`
  width: 100%;
  line-height: 4rem;
  font-size: 1.5rem;
  font-weight: 400;
  border-radius: 2rem;
  padding: 0 2rem;
  border: none;
  background: ${({ asWhite, theme: { colors } }) => (asWhite ? colors.white : colors.neutral10)};
  color: ${({ theme: { colors } }) => colors.black};

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.neutral20};
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  display: block;

  ${({ row }) =>
    row &&
    css`
      display: flex;
      align-items: center;

      > label {
        margin: 0 1rem 0 0;
      }
    `}
`;

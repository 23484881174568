import React from 'react';

import { FormSettingSpanSizes } from '../FormSettingSpanSizes/FormSettingSpanSizes';
import { FormAddingElementToSpan } from '../FormAddingElementToSpan/FormAddingElementToSpan';
import * as S from './Form.styled';

export const Form = () => (
  <S.Wrapper>
    <S.Column>
      <FormSettingSpanSizes />
    </S.Column>
    <S.Column>
      <FormAddingElementToSpan />
    </S.Column>
  </S.Wrapper>
);

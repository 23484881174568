import React from 'react';
import PropTypes from 'prop-types';

import * as S from './TechnicalCutoutsViewSpan.styled';

export const TechnicalCutoutsViewSpan = ({ width, height, scale, children }) => (
  <>
    <S.SpanElement height={height * scale} left />
    <S.Wrapper width={width} height={height} scale={scale}>
      {children}
    </S.Wrapper>
    <S.SpanElement height={height * scale} />
  </>
);

TechnicalCutoutsViewSpan.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  scale: PropTypes.number.isRequired,
};

TechnicalCutoutsViewSpan.defaultProps = {
  children: null,
};
